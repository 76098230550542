.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.container {
  padding: 10px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.videoWrapper {
  width: 100%;
  height: 100%;
  background-color: black;
  color: black;
  position: relative;
  overflow: hidden;
}

.applauseHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.applauseHolder .doneSummary {
  position: absolute;
  bottom: 10px;
  right: 10px
}

.titleHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iFrameHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.iFrameHolder iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.hidden {
  display: none;
}

.theEnd {
  position: absolute;
  width: 100%;
  height: 25%;
  left: 0;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.result {
  margin-top: 30px;
}

.results .response {
  font-size: 1.4em;
  text-align: right;
}

.results .videos {
  font-size: 2.0em;
  color: blue;
}

.backgroundHolder {
  z-index: -10;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  opacity: 0.5;
  filter: blur(10px);
}

.title {
  font-size: 6em;
}

.people {
  font-size: 4em;
  color: #00f
}
