.viewer {
  background: black;
  font: Arial;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.viewer .headingBanner {
  background-color: #287A9F;
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  position: relative;
}

.viewer .headingImage {
  max-height: 3em;
  margin-right: 0.5em;
}

.warmupDivider {
  border-top-width: 3px;
  border-color: white;
  opacity: 75;
}

.votingWrapper {

  max-width: 800px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.votingOption {
  display: flex;
  align-items: center;
  font-size: 24px;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  color: white;
  border-radius: 1em;
  border: transparent 3px solid;
  margin-bottom: 0.5em;
}

.votingOption:hover {
  background-color: #F68E20;
}

.votingOption.selected {
  border-color: #F68E20;
}

.headingBanner .form-switch {
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  right: 1em;
  top: 0;
  width: 8em;
  bottom: 0;
  height: fit-content;
}

.headingBanner .form-check-input:checked {
  border-color: #F68E20;
  background-color: #F68E20;
}

.votingWrapper.small {
  max-width: 500px;
}

.votingWrapper.small h3 {
  font-size: 24px;
}

.votingWrapper.small .votingOption {
  font-size: 20px;
}