.presentation {
  background: black;
  font: Arial;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.presentation .headingBanner {
  background-color: #287A9F;
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.presentation .headingImage {
  max-height: 3em;
  margin-right: 0.5em;
}

.warmupDivider {
  border-top-width: 3px;
  border-color: white;
  opacity: 75;
}

.barChart {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 20px;
}

.barChart .bar {
  background-color: #F68E20;
  height: 2em;
}

.startHeader {
  position: relative;
}

.startButton {
  position: absolute;
  right: 1em;
  top: 1em;
}